<template>
  <div>
    <b-card>
      <ValidationObserver ref="city" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
        <b-row>
          <b-col md="6">
            <input-form v-model="city.city.translations[0].name" :name="$t('cities.nameAr')" :label="$t('cities.nameAr')" validate="required" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="city.city.translations[1].name" :name="$t('cities.nameEn')" :label="$t('cities.nameEn')" validate="required" :disabled="disableStatus" />
          </b-col>

          <b-col md="12">
            <main-select :options="countries" :label="'name'" v-model="city.city.country_id" :reduce="i => i.id" :text="city.city.countryName" :labelTitle="$t('countries.item')" validate="required" :showAsInfo="disableStatus" />
          </b-col>
        </b-row>

        <form-btns v-if="currentPage != itemPages.view" :label="submitLabel" :loading="loading" />
      </b-form>
      </ValidationObserver>
    </b-card>

    <b-card v-if="currentPage == itemPages.view">
      <locations :cityId="id" :titleStatus="true" />
    </b-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import cityServices from '../services/cities'
import countriesServices from '../../countries/services/countries'
import City from '../models/City'
import Locations from '../../locations/views/Locations.vue'
import moduleItemMixin from '../../../../Mixins/moduleItemMixin'

export default {
  mixins: [moduleItemMixin],
  components: { Locations },
  data () {
    return {
      city: new City(),
      countries: []
    }
  },
  methods: {
    async create () {
      cityServices.create(this.city).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'generalSettings.cities' })
      }).catch(() => {
        this.loading = false
      })
    },
    async update () {
      cityServices.update(this.id, this.city).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'generalSettings.cities' })
      }).catch(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.id) {
      cityServices.findOne(this.id).then(response => {
        this.city.fillData(response.data.data)
        this.loading = false
      })
    } else {
      this.loading = false
    }

    if (!this.disableStatus) {
      countriesServices.findAll().then((res) => {
        this.countries = res.data.data
      })
    }
  }
}
</script>
