export default class City {
  constructor () {
    this.setInitialValue()
  }

  setInitialValue () {
    this.city = {
      country_id: null,
      countryName: '',
      translations: [
        { id: null, name: '', lang: 'ar' },
        { id: null, name: '', lang: 'en' }
      ],
      locations: []
    }
  }

  fillData (data) {
    if (data) {
      const itemAr = data.translations?.find(o => o.lang === 'ar')
      const itemEn = data.translations?.find(o => o.lang === 'en')

      this.city.country_id = data.country_id ? data.country_id : null
      this.city.countryName = data.countryName ? data.countryName : ''
      this.city.translations[0].id = itemAr ? itemAr.id : null
      this.city.translations[0].name = itemAr ? itemAr.name : ''
      this.city.translations[1].id = itemEn ? itemEn.id : null
      this.city.translations[1].name = itemEn ? itemEn.name : ''
      this.city.locations = data.locations ? data.locations : []
    } else {
      this.setInitialValue()
    }
  }
}
